





































import Vue from "vue";
import InterviewLoading from "@/components/candidate/interviews/InterviewLoading.vue";
import AppResourseNotFound from "@/components/shared/AppResourseNotFound.vue";
import AccessDeniedComponent from "@/components/shared/AppAccessDeniedComponent.vue";
import {
  CAMERA_RECORDING_PERMISSION,
  CAMERA_RECORDING_SUPPORTED,
  CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT,
  CANDIDATE_INTERVIEW_STOP
} from "@/store/modules/candidate_interview/constants";
import { mapGetters, mapMutations } from "vuex";
import { get_random_number_between } from "@/utils/global";
import CandidateInterviewV2 from "@/views/candidate/interviews/CandidateInterviewV2.vue";
export default Vue.extend({
  name: "CandidateInterviewWrapper",
  components: {
    InterviewLoading,
    AppResourseNotFound,
    AccessDeniedComponent,
    CandidateInterviewV2
  },
  computed: {
    ...mapGetters("candidate_interviews", {
      get_camera_recording_supported: CAMERA_RECORDING_SUPPORTED,
      get_camera_recording_permission: CAMERA_RECORDING_PERMISSION,
      get_interview_stop_status: CANDIDATE_INTERVIEW_STOP
    })
  },
  data() {
    return {
      loading: true,
      error: "",
      camera_recording_upload_chunk_size: 6 * 1024 * 1024,
      camera_media_stream: null as MediaStream | null, // For camera stream
      audio_media_stream: null as MediaStream | null, // For audio stream
      camera_recorded_blobs: [] as Blob[], // For camera recording chunks
      // eslint-disable-next-line no-undef
      speech_recognition: null as typeof window.webkitSpeechRecognition | null,
      camera_media_recorder: null as MediaRecorder | null, // For camera recording
      // For audio recording
      audio_recorder: null as MediaRecorder | null, // For audio recording
      audio_recorder_chunks: [] as Blob[], // For audio recording chunks
      user_ans_recorder_chunks: [] as Blob[], // For user individual answer recording chunks
      individual_ans_blob: null as Blob | null // For individual answer blob
    };
  },
  watch: {
    get_interview_stop_status(new_val) {
      if (new_val) {
        this.interview_finished();
      }
    }
  },
  async created() {
    const camera_random_number = get_random_number_between(8, 15);
    this.camera_recording_upload_chunk_size =
      camera_random_number * 1024 * 1024;
    await this.invoke_camera();
  },
  methods: {
    ...mapMutations("candidate_interviews", {
      set_camera_recording_supported: CAMERA_RECORDING_SUPPORTED,
      set_camera_recording_permission: CAMERA_RECORDING_PERMISSION,
      set_ans_transcript: CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT
    }),
    async invoke_camera() {
      if (navigator?.mediaDevices?.getUserMedia) {
        try {
          const result = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
          }); // Ask for camera and audio permission
          const audio_stream = await navigator.mediaDevices.getUserMedia({
            audio: true
          }); // Ask for audio permission
          if (result.active) {
            this.audio_media_stream = audio_stream;
            this.camera_media_stream = result;
            await this.config_speech_recognition();
            await this.config_video_recording(result);
            this.set_camera_recording_permission(true);
            this.error = "";
          } else {
            this.set_camera_recording_permission(false);
            this.error = "Unable to access camera and audio.";
          }
          this.set_camera_recording_supported(true);
          this.loading = false;
        } catch (e) {
          this.error = "Unable to access camera and audio.";
          this.set_camera_recording_permission(false);
          this.loading = false;
        }
      } else {
        this.error = "Your browser doesn't support camera recording.";
        this.set_camera_recording_supported(false);
        this.loading = false;
      }
    },
    async config_speech_recognition() {
      try {
        const speech_recognition = window.webkitSpeechRecognition;
        this.speech_recognition = new speech_recognition();
        this.speech_recognition.interimResults = true;
        this.speech_recognition.continuous = true;
        this.speech_recognition.onresult = this.handle_user_speech;
      } catch (e) {
        throw "Speech recognition not supported.";
      }
    },
    // eslint-disable-next-line no-undef
    handle_user_speech(result: any) {
      const transcript = Array.from(result.results)
        // eslint-disable-next-line no-undef
        .map((result: any) => {
          if (result.isFinal) return result[0].transcript;
        })
        .join("");
      this.set_ans_transcript(transcript);
    },
    async config_video_recording(stream: MediaStream) {
      try {
        this.camera_media_recorder = new MediaRecorder(stream);
        this.camera_media_recorder.start(15000);
        const video = this.$refs.camera as HTMLVideoElement;
        video.srcObject = stream;
        this.camera_media_recorder.ondataavailable = this.media_recorder_data;
        this.camera_media_recorder.onstop = this.handle_media_recorder_stop;
        // if (this.audio_media_stream)
        //   this.set_audio_recorder(this.audio_media_stream);
      } catch (e) {
        throw "Error in recording video.";
      }
    },
    set_audio_recorder(stream: MediaStream) {
      const audioTrack = stream.getAudioTracks()[0];
      this.audio_recorder = new MediaRecorder(new MediaStream([audioTrack]));
      this.user_ans_recorder_chunks = [];
      this.audio_recorder.ondataavailable = (event) => {
        if (audioTrack.enabled && event.data.size > 0) {
          this.user_ans_recorder_chunks.push(event.data);
          this.audio_recorder_chunks.push(event.data);
        }
      };
      this.audio_recorder.pause = () => {
        this.construct_audio_url();
      };
    },
    handle_media_recorder_stop() {
      if (this.camera_media_recorder) {
        this.camera_media_recorder.stream
          .getTracks()
          .forEach((track) => track.stop());
        this.camera_media_recorder.ondataavailable = null;
        this.camera_media_recorder.onstop = null;
        this.camera_media_recorder = null;
        this.camera_media_stream = null;
        this.audio_media_stream = null;
      }
    },
    speak_config(payload: boolean) {
      if (payload) this.speech_recognition?.start();
      else this.speech_recognition?.stop();
    },
    async media_recorder_data(data: BlobEvent) {
      if (data.data && data.data.size > 0) {
        this.camera_recorded_blobs.push(data.data);
      }
      if (
        this.camera_recorded_blobs.reduce(
          (sum, chunk) => sum + chunk.size,
          0
        ) >= this.camera_recording_upload_chunk_size
      ) {
        // this.upload_recording_chunks(AssessmentRecordType.CAMERA);
      }
    },
    construct_audio_url() {
      if (this.user_ans_recorder_chunks.length > 0) {
        this.individual_ans_blob = new Blob(this.user_ans_recorder_chunks, {
          type: "audio/wav"
        });
        const audioUrl = URL.createObjectURL(this.individual_ans_blob);
      }
    },
    interview_finished() {
      this.camera_media_recorder?.stop();
      this.audio_recorder?.stop();
      this.speech_recognition?.stop();
    }
  },
  destroyed() {
    this.camera_media_recorder?.stop();
    this.audio_recorder?.stop();
    this.speech_recognition?.stop();
  }
});

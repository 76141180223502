











import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import {
  CANDIDATE_INTERVIEW_DETAILS,
  CANDIDATE_INTERVIEW_HISTORY,
  CANDIDATE_INTERVIEW_ID,
  CANDIDATE_INTERVIEW_LOADING,
  CANDIDATE_INTERVIEW_STOP,
  RESET_CANDIDATE_INTERVIEW_STATE
} from "@/store/modules/candidate_interview/constants";
import {
  FETCH_INTERVIEW_HISTORY,
  INTERVIEW_LISTING,
  UPDATE_INTERVIEW_STATUS
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { parseStrToInt } from "@/utils/global";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import InterviewLoading from "@/components/candidate/interviews/InterviewLoading.vue";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import CandidateInterviewWrapper from "@/views/candidate/interviews/CandidateInterviewWrapper.vue";
import { InterviewRoles } from "@/interfaces/candidate/candidate_interview";
import moment from "moment";
export default Vue.extend({
  name: "CandidateInterviewRoot",
  components: {
    InterviewLoading,
    CandidateInterviewWrapper
  },
  async beforeRouteEnter(to, from, next) {
    const param_id = to.params.interview_id;
    if (!param_id || !parseStrToInt(param_id)) next("/candidate/interviews");
    else next();
  },
  beforeRouteUpdate(to, from, next) {
    const param_id = to.params.interview_id;
    if (!param_id || !parseStrToInt(param_id)) next("/candidate/interviews");
    else next();
  },
  computed: {
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    }),
    ...mapGetters("candidate_interviews", {
      get_interview_loading: CANDIDATE_INTERVIEW_LOADING,
      get_interview_completed: CANDIDATE_INTERVIEW_STOP
    })
  },
  data() {
    return {
      bot_image: require("@/assets/images/female-bot.png"), // Bot image
      interview_id: null as number | null, // Interview id
      interview_cancelled: false, // Interview cancelled
      route_leave: false // Route leave
    };
  },
  async created() {
    this.set_candidate_interview_loading(true); // set loading in store
    const interview_id = parseStrToInt(this.$route.params.interview_id); // get interview id from route
    if (!interview_id) {
      // check if interview id is not valid
      this.root_error("Invalid interview id"); // set error in store
      this.$router.push("/candidate/interviews"); // redirect to interviews page
      this.set_candidate_interview_loading(false); // set loading in store
      return;
    }
    this.interview_id = interview_id; // set interview id in store if valid
    if (
      localStorage.getItem("interview_cancel") == this.interview_id.toString()
    ) {
      localStorage.removeItem("interview_cancel");
      this.interview_cancelled = true;
      await this.finish_interview();
    }
    this.set_interview_id(interview_id); // set interview id in store if valid
    const interview_details = await this.fetch_interview_details({
      interview_id,
      candidate_id: this.get_user.id
    }); // fetch interview details
    // check if interview details are not found
    if (!interview_details || interview_details.length <= 0) {
      this.root_error("Interview not found"); // set error in store
      this.$router.push("/candidate/interviews"); // redirect to interviews page
      this.set_candidate_interview_loading(false); // set loading in store
      return;
    }
    const interview = interview_details[0]; // get interview details
    if (interview.interview_status === Interview.Status.Finished) {
      this.root_notification("Interview has been finished"); // set notification in store
      this.$router.push("/candidate/interviews"); // redirect to interviews page
      this.set_candidate_interview_loading(false); // set loading in store
      return;
    }
    this.set_interview_details(interview); // set interview details in store
    const interview_history = await this.fetch_interview_history(interview_id); // fetch interview history
    if (interview_history?.length > 0)
      await this.process_interview_history(interview_history);
    this.set_candidate_interview_loading(false);
    this.set_interview_stop_status(false);
    this.interview_cancelled = false;
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapMutations("candidate_interviews", {
      set_interview_id: CANDIDATE_INTERVIEW_ID,
      set_candidate_interview_loading: CANDIDATE_INTERVIEW_LOADING,
      set_interview_details: CANDIDATE_INTERVIEW_DETAILS,
      reset_candidate_interview: RESET_CANDIDATE_INTERVIEW_STATE,
      set_interview_history: CANDIDATE_INTERVIEW_HISTORY,
      set_interview_stop_status: CANDIDATE_INTERVIEW_STOP
    }),
    ...mapActions("recruiter", {
      fetch_interview_details: INTERVIEW_LISTING,
      fetch_interview_history: FETCH_INTERVIEW_HISTORY,
      update_interview_status: UPDATE_INTERVIEW_STATUS
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    // Function to process interview history
    // Filter interview history
    // Remove interview history which contains job description, job skills, personal details & candidate profile
    // Set interview history
    // Update chat cursor
    async process_interview_history(
      history: Interview.InterviewHistoryResponse[]
    ) {
      const interview_history = history
        .filter(
          (val) =>
            val.role !== InterviewRoles.SYSTEM &&
            !val.content
              ?.toLowerCase()
              .includes("job description and job skills is provided above") &&
            !val.content?.toLowerCase().includes("personal_details") &&
            !val.content?.toLowerCase().includes("candidate profile")
        )
        .map((val) => ({
          id: val.id,
          role: val.role,
          content: val.content,
          created_at: moment(val.created_at).format("ddd, h:mm A").toString(),
          picture:
            val.role === InterviewRoles.USER
              ? this.get_user.avatar_uri
              : this.bot_image
        }));
      this.set_interview_history(interview_history);
    },
    async finish_interview() {
      this.set_interview_stop_status(true);
      const res = await this.update_interview_status({
        interview_id: this.interview_id,
        action: Interview.Status.Finished,
        filename: ""
      });
      if (!res) {
        this.set_root_error(this.$t("errors.internal"));
        await this.$router.push("/candidate/interviews");
        return;
      }
      if (this.route_leave) return;
      this.set_root_notification("Interview Completed Successfully");
      await this.$router.push("/candidate/interviews");
    }
  },
  beforeDestroy() {
    this.reset_candidate_interview(); // reset candidate interview state
  },
  // Guard to run before navigation leave current route
  async beforeRouteLeave(to, from, next) {
    if (this.get_interview_completed || this.interview_cancelled) return next();
    if (
      window.confirm(this.$t("candidate.interview.cancel-heading").toString())
    ) {
      this.route_leave = true;
      localStorage.removeItem("interview_cancel");
      this.finish_interview();
      next();
    } else {
      // If user cancels, prevent navigation
      next(false);
    }
  }
});
